<template>
    <div class="help-box">
        <Header :showMainNav="false" :showUserNav="true">
            <template #help-nav>
                <template v-for="item in projectPlatforms" :key="item">
                    <Dropdown class="drop-down-nav" :trigger="['click', 'hover']">
                        <span :class="['nav-item', { active: formData.type === item }]" @click="init(item)">{{ platform[item] }}</span>
                        <template #content>
                            <template v-for="(title, docId) in docMap[locale][item]" :key="docId">
                                <Doption @click="getDocUrl(docId, item)">
                                    <span :class="['doc-item', { active: formData.docId === docId }]">{{ title }}</span>
                                </Doption>
                            </template>
                        </template>
                    </Dropdown>
                </template>
            </template>
        </Header>
        <iframe :src="formData.url" title="help"></iframe>
        <div class="copyright">Copyright 2022 PAX Technology.All Rights Reserved v2.0.0</div>
    </div>
</template>
<script>
import { defineComponent, reactive, inject, ref } from 'vue'
import Header from '@/views/layout/Header.vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
    components: {
        Header
    },
    setup() {
        const { locale } = useI18n()
        const formData = reactive({
            url: '',
            type: '',
            docId: ''
        })
        const http = inject('$http')
        const route = useRoute()
        const router = useRouter()
        const projectPlatforms = ref([])
        const platform = {
            nuxiums: 'Nuxiums',
            runthos: 'RunthOS'
        }
        const docMap = {
            'zh-CN': {
                nuxiums: {
                    'e5bbaed5b4bffacf75a2951ac374a95c': 'Nuxiums 白皮书',
                    'b3c0d84d395c7c7113e40aa15ffce370': 'Prolin 应用编程接口编程指南',
                    'f5dd8ada2c99ae76b16e05b81b12da10': 'Prolin 应用开发手册',
                    // '892a0047a97af96d4c9f3dc5bbf387d8': 'Prolin Monitor虚拟机接口说明(V1.0.2)',
                    // '89547201e3dc06130b6584af266cc6f8': 'Prolin Monitor虚拟机编程指南',
                    'e3e770ca428c0dfeb55972db59295c73': 'Prolin PPP direct接口说明',
                    '157f5e518fa83b1f8d427dd74d5fd2c5': 'Prolin 触发参考手册',
                    '49f00864da3390fe0642bede40075c05': 'Prolin 蓝牙接口说明',
                    'cd6295893f391392713fcb00e03f5da5': 'Prolin 蓝牙编程指南'
                },
                runthos: {
                    '3465899e7bc6f29b2a455f7c3a6ae6f4': 'RunthOS API 编程指南'
                }
            },
            'en-US': {
                nuxiums: {
                    '88d196161e05fc9b5b64049e34f92f07': 'Nuxiums White Paper',
                    'e4554cb1be48a14b6739f292bae6185d': 'Prolin API Programming Guide',
                    'a7e6b8740fa34d97db915e0469df169b': 'Prolin Application Development Manual',
                    // b8e161d67eaf5b75f57c25d5d3f41d97: 'Prolin-Monitor Virtual Machine Interface',
                    // '1e3408782814d9614a513440d6637b86': 'Prolin-Monitor Virtual Machine Programming Guide',
                    'e08173bdcce33893f7f3d8cfa1d04dfe': 'Prolin PPP Direct Interface',
                    'd164a2ea0a25cd36b9f227d5dcd2aba2': 'Prolin Tamper Reference Guide',
                    'a4d198e9d980412f3fb0b917d430114a': 'Prolin Bluetooth Interface',
                    'c5c6a678da621758e49384d647890d36': 'Prolin Bluetooth Programming Guide'
                },
                runthos: {
                    '9e22c18c6473ac65f561b01727e2e926': 'RunthOS API Programming Guide'
                }
            }
        }

        const getDocUrl = (docId, type) => {
            if (docId) {
                formData.type = type
                formData.docId = docId
                http.account.getDocUrl(docId).then(({ data }) => {
                    formData.url = data.url //.replace('haniu.paxsz.com:8443', 'ppm3.paxsz.com:9443')
                })
            } else {
                formData.url = ''
            }
        }

        http.account.getUserInfo().then(({ data }) => {
            projectPlatforms.value = data.projectPlatforms.split(',').sort()
            if (projectPlatforms.value.length === 0) {
                router.push('/')
            }
            init(route.query.type ? route.query.type : projectPlatforms.value[0])
        })

        const init = (type) => {
            formData.type = type
            const docId = Object.keys(docMap[locale.value][formData.type])[0]
            getDocUrl(docId, type)
        }

        return { formData, locale, docMap, init, getDocUrl, projectPlatforms, platform }
    }
})
</script>
<style scoped lang="less">
.help-box {
    height: 100%;
}
iframe {
    height: calc(100% - 74px);
    width: 100%;
    border: 0;
    display: inherit;
    margin-top: 6px;
}
.doc-item {
    padding: 0 26px;
    &.active {
        color: #2b32c9;
    }
}
.copyright {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    padding-right: 100px;
    height: 28px;
    line-height: 28px;
    color: #1d1d1d;
}
</style>
